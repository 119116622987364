import Header from "tbme/header";
import Footer from "wes/footer";
import ClientProvider from "tbme/clientProvider";
import TbcClientProvider from "tbtbc/clientProvider";
import CommunityBanner from "tbui/communityBanner";
import Tally from "tbui/tally";
import CardFooterLink from "tbui/cardFooterLink";
import Card from "tbui/card";
import ProfileLayout from "tbui/profileLayout";
import PrivateProfileBanner from "tbui/privateProfileBanner";
import TdsThemeProvider from "tds/themeProvider";
import PrivateProfile from "tbui/privateProfile";
import PageLayout from "tbui/pageLayout";
import Badge from "tbui/badge";
import Popover from "tbui/popover";
import StayInBounds from "tbui/stayInBounds";
import Rank from "tbme/rank";
import Skills from "tbme/skills";
import SkillButton from "tbui/skillButton";
import SkillsRing from "tbui/skillsRing";
import SkillsChart from "tbui/skillsChart";
import ProgressAvatar from "tbui/progressAvatar";
import EmptyItem from "tbui/emptyItem";
import ProfileBadges from "tbme/profileBadges";
import QuestionsAndAnswers from "tbtbc/questionsAndAnswers";
import Connections from "tbtbc/connections";
import TbcUserActions from "tbtbc/userActions";
import ButtonIconText from "tbui/buttonIconText";
import ButtonStateful from "tbui/buttonStateful";
import AboutMe from "tbme/aboutMe";
import TbcFollowButton from "tbtbc/followButton";
import IconText from "tbui/iconText";
import ButtonIconTextLink from "tbui/buttonIconTextLink";
import NoActivity from "tbtbc/noActivity";
import MockProvider from "tbme/mockProvider";
import ProfileMetadata from "tbme/profileMetadata";
import LocalState from "tbme/localState";
import EmptySuperbadgeItem from "tbui/emptySuperbadgeItem";
import SuperbadgeItem from "tbui/superbadgeItem";
import Superbadges from "tbme/superbadges";
import CommunityTags from "tbme/communityTags";
import CommunityTag from "tbui/communityTag";
import CommunityTagsEdit from "tbme/communityTagsEdit";
import CommunityTagModal from "tbui/communityTagModal";
import DuelingPicklist from "tbui/duelingPicklist";
import HireMeBanner from "tbui/hireMeBanner";
import Certifications from "tbme/certifications";
import CertificationItem from "tbui/certificationItem";
import CertificationMessage from "tbui/certificationMessage";
import CertificationsGroup from "tbui/certificationsGroup";
import PageFooter from "tbui/pageFooter";
import ProfileEdit from "tbme/profileEdit";
import Avatar from "tbme/avatar";
import AvatarUpload from "tbui/avatarUpload";
import FileUploader from "tbui/fileUploader";
import UserDetails from "tbme/userDetails";
import UserDetailItem from "tbui/userDetailItem";
import AboutMyself from "tbme/aboutMyself";
import Mvp from "tbme/mvp";
import TbcConnections from "tbme/connections";
import Files from "tbme/files";
import TBTBCFiles from "tbtbc/files";
import SocialLinks from "tbme/socialLinks";
import TbmeQuestionsAndAnswers from "tbme/questionsAndAnswers";
import MockTbtbcProvider from "../elements/tbme/mockTbtbcProvider/mockTbtbcProvider";
import UserActions from "tbme/userActions";
import FollowButton from "tbme/followButton";
import ChassisProfileMetadata from "tbme/chassisProfileMetadata";
import CareerMarketplace from "tbme/careerMarketplace";
import learnerStatusLevel from "tbui/learnerStatusLevel";

export * as localState from "tbme/localState";
export * as clientAdapter from "tbme/clientAdapter";
export * as localization from "tbme/localization";
export * as IisModuleAdapter from "tbme/iisModuleAdapter";
export * as profilePage from "tbme/profilePage";

/** Define Elements */
customElements.define(
  "tds-theme-provider",
  TdsThemeProvider.CustomElementConstructor
);
customElements.define(
  "tbme-client-provider",
  ClientProvider.CustomElementConstructor
);
customElements.define(
  "tbtbc-client-provider",
  TbcClientProvider.CustomElementConstructor
);
customElements.define("tbme-header", Header.CustomElementConstructor);
customElements.define(
  "tbui-community-banner",
  CommunityBanner.CustomElementConstructor
);
customElements.define("tbui-tally", Tally.CustomElementConstructor);
customElements.define(
  "tbui-card-footer-link",
  CardFooterLink.CustomElementConstructor
);
customElements.define("wes-footer", Footer.CustomElementConstructor);
customElements.define("tbui-card", Card.CustomElementConstructor);
customElements.define("tbme-local-state", LocalState.CustomElementConstructor);
customElements.define(
  "tbui-learner-status-level",
  learnerStatusLevel.CustomElementConstructor
);
customElements.define(
  "tbui-profile-layout",
  ProfileLayout.CustomElementConstructor
);
customElements.define(
  "tbui-private-profile-banner",
  PrivateProfileBanner.CustomElementConstructor
);
customElements.define(
  "tbui-private-profile",
  PrivateProfile.CustomElementConstructor
);
customElements.define("tbui-page-layout", PageLayout.CustomElementConstructor);
customElements.define("tbui-badge", Badge.CustomElementConstructor);
customElements.define("tbui-popover", Popover.CustomElementConstructor);
customElements.define(
  "tbui-stay-in-bounds",
  StayInBounds.CustomElementConstructor
);
customElements.define("tbme-rank", Rank.CustomElementConstructor);
customElements.define("tbme-skills", Skills.CustomElementConstructor);
customElements.define(
  "tbui-skill-button",
  SkillButton.CustomElementConstructor
);
customElements.define("tbui-skills-ring", SkillsRing.CustomElementConstructor);
customElements.define(
  "tbui-skills-chart",
  SkillsChart.CustomElementConstructor
);
customElements.define(
  "tbui-progress-avatar",
  ProgressAvatar.CustomElementConstructor
);
customElements.define("tbui-empty-item", EmptyItem.CustomElementConstructor);
customElements.define(
  "tbme-profile-badges",
  ProfileBadges.CustomElementConstructor
);
customElements.define(
  "tbtbc-questions-and-answers",
  QuestionsAndAnswers.CustomElementConstructor
);
customElements.define(
  "tbtbc-connections",
  Connections.CustomElementConstructor
);
customElements.define(
  "tbtbc-user-actions",
  TbcUserActions.CustomElementConstructor
);
customElements.define(
  "tbui-button-icon-text",
  ButtonIconText.CustomElementConstructor
);
customElements.define(
  "tbui-button-stateful",
  ButtonStateful.CustomElementConstructor
);
customElements.define("tbme-about-me", AboutMe.CustomElementConstructor);
customElements.define(
  "tbtbc-follow-button",
  TbcFollowButton.CustomElementConstructor
);
customElements.define("tbui-icon-text", IconText.CustomElementConstructor);
customElements.define(
  "tbui-button-icon-text-link",
  ButtonIconTextLink.CustomElementConstructor
);
customElements.define("tbtbc-no-activity", NoActivity.CustomElementConstructor);
customElements.define(
  "tbme-mock-provider",
  MockProvider.CustomElementConstructor
);
customElements.define(
  "tbtbc-mock-provider",
  MockTbtbcProvider.CustomElementConstructor
);
customElements.define(
  "tbme-profile-metadata",
  ProfileMetadata.CustomElementConstructor
);
customElements.define(
  "tbui-empty-superbadge-item",
  EmptySuperbadgeItem.CustomElementConstructor
);
customElements.define(
  "tbui-superbadge-item",
  SuperbadgeItem.CustomElementConstructor
);
customElements.define("tbme-superbadges", Superbadges.CustomElementConstructor);
customElements.define(
  "tbme-community-tags",
  CommunityTags.CustomElementConstructor
);
customElements.define(
  "tbui-community-tag",
  CommunityTag.CustomElementConstructor
);
customElements.define(
  "tbui-community-tag-modal",
  CommunityTagModal.CustomElementConstructor
);
customElements.define(
  "tbui-dueling-picklist",
  DuelingPicklist.CustomElementConstructor
);
customElements.define(
  "tbme-community-tags-edit",
  CommunityTagsEdit.CustomElementConstructor
);
customElements.define(
  "tbui-hire-me-banner",
  HireMeBanner.CustomElementConstructor
);
customElements.define(
  "tbme-certifications",
  Certifications.CustomElementConstructor
);
customElements.define(
  "tbui-certification-item",
  CertificationItem.CustomElementConstructor
);
customElements.define(
  "tbui-certification-message",
  CertificationMessage.CustomElementConstructor
);
customElements.define(
  "tbui-certifications-group",
  CertificationsGroup.CustomElementConstructor
);
customElements.define("tbui-page-footer", PageFooter.CustomElementConstructor);
customElements.define(
  "tbme-profile-edit",
  ProfileEdit.CustomElementConstructor
);
customElements.define("tbme-avatar", Avatar.CustomElementConstructor);
customElements.define(
  "tbui-avatar-upload",
  AvatarUpload.CustomElementConstructor
);
customElements.define(
  "tbui-file-uploader",
  FileUploader.CustomElementConstructor
);
customElements.define(
  "tbme-user-details",
  UserDetails.CustomElementConstructor
);
customElements.define(
  "tbui-user-detail-item",
  UserDetailItem.CustomElementConstructor
);
customElements.define(
  "tbme-about-myself",
  AboutMyself.CustomElementConstructor
);
customElements.define("tbme-mvp", Mvp.CustomElementConstructor);
customElements.define(
  "tbme-connections",
  TbcConnections.CustomElementConstructor
);
customElements.define("tbme-files", Files.CustomElementConstructor);
customElements.define("tbtbc-files", TBTBCFiles.CustomElementConstructor);
customElements.define(
  "tbme-social-links",
  SocialLinks.CustomElementConstructor
);
customElements.define(
  "tbme-questions-and-answers",
  TbmeQuestionsAndAnswers.CustomElementConstructor
);
customElements.define(
  "tbme-user-actions",
  UserActions.CustomElementConstructor
);
customElements.define(
  "tbme-follow-button",
  FollowButton.CustomElementConstructor
);
customElements.define(
  "tbme-chassis-profile-metadata",
  ChassisProfileMetadata.CustomElementConstructor
);
customElements.define(
  "tbme-career-marketplace",
  CareerMarketplace.CustomElementConstructor
);
