import { api, LightningElement, wire } from "lwc";
import { Certification } from "tbme/certifications";
import { locale } from "tbme/localState";
import { LabelTranslations, MultiLabelAdapter, t } from "tbme/localization";
export default class extends LightningElement {
  @api certification?: Certification;
  @api isSelf: boolean = false;
  sanitizedHTMLConfig = {
    ALLOWED_TAGS: ["b", "div", "span", "p"],
    ALLOWED_ATTR: ["style", "class"],
  };
  _statusColor = "";
  _htmlBody = "";
  private labels: LabelTranslations = undefined!;

  /** Getter for the inline styling status color */
  get statusColor() {
    return `color:${this.certification?.status.color}`;
  }

  /** Getter for the body of the certification to be rendered as html */
  get htmlBody() {
    return `<span class="text_secondary text_gray">${this.certification?.publicDescription}</span>`;
  }

  /** Getter for the formatted status date */
  get statusDate() {
    const date = this.certification!.status.date
      ? new Date(
          this.certification!.status.date.replace(/-/g, "/")
        ).toLocaleDateString(locale(), {
          month: "short",
          day: "numeric",
          year: "numeric",
        })
      : null;
    return date
      ? t("certifications.maintenanceDueDateLabel", {
          status: this.certification?.status.title,
          dueDate: date,
        })
      : null;
  }

  /** Getter for the colored status.
   * Returns the colored status and the maintenance due date
   * Or
   * Returns just the colored status
   */
  get status() {
    if (this.statusDate) {
      return this.statusDate.replace(
        this.certification!.status.title as string,
        `<span class="text_heavy" style=${this.statusColor}>${
          this.certification!.status.title
        }</span>`
      );
    }
    return `<span class="text_heavy" style=${this.statusColor}>${
      this.certification!.status.title
    }</span>`;
  }

  /** Returns true if the certification has a download url, is sorting by status, and false otherwise */
  get hasDownloadLogoLink() {
    return !!this.certification?.downloadLogoUrl && this.isSelf;
  }

  /** Getter for either the expired label or issued label depending on the status */
  get completedExpiredDate() {
    const dateOptions: Intl.DateTimeFormatOptions = {
      month: "short",
      year: "numeric",
    };
    if (
      this.certification?.dateExpired &&
      this.certification.status.expired &&
      this.isSelf
    ) {
      const dateExpired = new Date(
        this.certification.dateExpired.replace(/-/g, "/")
      ).toLocaleDateString(locale(), dateOptions);
      return dateExpired
        ? t("certifications.expiredLabel", {
            dateExpired: dateExpired,
          })
        : null;
    }
    if (this.certification?.dateExpired) {
      const dateIssued = new Date(
        this.certification!.dateCompleted!.replace(/-/g, "/")
      ).toLocaleDateString(locale(), dateOptions);
      const dateExpired = new Date(
        this.certification!.dateExpired!.replace(/-/g, "/")
      ).toLocaleDateString(locale(), dateOptions);
      return `${dateIssued} - ${dateExpired}`;
    }
    const dateIssued = new Date(
      this.certification!.dateCompleted.replace(/-/g, "/")
    ).toLocaleDateString(locale(), dateOptions);
    return dateIssued
      ? t("certifications.issuedLabel", {
          dateIssued: dateIssued,
        })
      : null;
  }

  /** Getter for the user legal name */
  get userLegalName() {
    if (this.certification?.certificationUserLegalName && !this.isSelf) {
      return t("certifications.certificationUserLegalName", {
        userLegalName: this.certification.certificationUserLegalName,
      });
    }
  }

  /** Getter for whether or not to show the CTA */
  get showActionButton() {
    return (
      this.certification?.cta?.label &&
      this.certification?.cta?.url &&
      this.isSelf
    );
  }

  @wire(MultiLabelAdapter, {
    labels: ["certifications.downloadLogo"],
  })
  private handleLabels(labels: { certifications: LabelTranslations }) {
    this.labels = {
      ...labels.certifications,
    };
  }
}
