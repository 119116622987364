import { api, LightningElement } from "lwc";

export default class extends LightningElement {
  /** The image url for the status item */
  @api imageUrl: string = "";

  /** The status name of the item */
  @api statusName: string = "";

  /** The title of the item */
  @api title: string = "";

  get altText() {
    return `${this.statusName} ${this.title}`;
  }
}
